<template>
	<div>
		<div class="toolBar mBottom10 clearfix">
			<!-- <el-button type="primary" size='small' plain @click="business">分配业务</el-button> -->
			<el-button type="primary" size='small' plain @click="isShowSearchDlg = true">查询</el-button>
			<el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
		</div>

		<el-card class="box-card" style="margin-top: 10px">
			<el-table :data="orgBusinessData"  size='small' stripe :height='tableHeight'>
				<el-table-column type="index" align="center" label='序号'></el-table-column>
				<el-table-column prop="orgName" label="机构名称"></el-table-column>
				<el-table-column prop="moduleName" label="业务名称"></el-table-column>
				<el-table-column  label="操作" align="center" width="100">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="disBusiness(scope.$index,scope.row)">分配业务</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
		<!-- 分配业务 -->
		<el-dialog v-dialogDrag :visible.sync="orgBusinessDialog" width="40%" title="分配业务">
				<el-form label-width="90px" ref='orgBusinessForm' :model='orgBusinessForm' size='small'>
					<el-form-item label="机构名称：" style="width: 50%;margin:auto" v-show="clickAdd==true">
						<el-cascader  :options="orgBusinessForm.organizationIds" v-model="orgBusinessForm.organizationId" :props="{value: 'id', label: 'name'}" @change="handleChange" style="width: 100%" size='small'></el-cascader>
					</el-form-item>
					<div style="width: 90%;margin: 30px auto 0 auto" v-show="orgBusinessForm.organizationIds">
						<el-checkbox v-for="business in businession" v-model="orgBusinessForm.checkList"  :label="business.name" :key="business.id" border style="margin: 15px"></el-checkbox>
					</div>
				</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="orgBusinessDialog = false" size='small'>取消</el-button>
				<el-button  type="primary" size='small' @click="submit">保存</el-button>
			</div>
		</el-dialog>
		<div class="toolBar clearfix" style="margin-top: 10px">
			<pagi-nation :page='orgBusinessPage' @sizeChange='orgBusinessSizeChange' @currentChange='orgBusinessCurrentChange'></pagi-nation>
		</div>

		<!-- 查询 -->
        <el-dialog v-dialogDrag title="查询" :visible.sync="isShowSearchDlg">
            <el-form :model="searchItem" label-width="100px" ref="searchItem">
                <el-form-item label="机构名称" prop="orgName">
                    <el-cascader :props="orgProps" :options="global.orgList" :show-all-levels="false" v-model="searchItem.orgName" size='small' placeholder="请选择所属机构" class="w100">
                    </el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button plain size="small" @click="isShowSearchDlg = false">取 消</el-button>
                <el-button plain size="small" type="primary" @click="load">确 定</el-button>
            </span>
        </el-dialog>
	</div>
</template>

<script>
	class businessForm {
		constructor() {
			this.organizationId=null;
			this.organizationIds = [];
			this.checkList = [];
		}
		reset() {
			this.constructor();
		}
	}

	class SearchItem {
        constructor() {
            this.orgName = []
        }
        reset () {
            this.constructor()
        }
    }
	export default {
		data(){
			return{
				clickAdd:false,
				orgBusinessData:[],
				orgBusinessPage:new this.Page(),
				orgBusinessDialog:false,
				orgBusinessForm:new businessForm(),
				businession:[],
				id:null,
				tableHeight: document.documentElement.clientHeight - 288,
				//查询相关
				isShowSearchDlg: false,
				searchItem: new SearchItem(),
				orgProps: {label: 'name', value: 'name', checkStrictly: true},
			}
		},
        computed : {

        },
		methods:{
			resetForm(formName) {
				if(this.$refs[formName] !== undefined) {
					this.$refs[formName].resetFields();
				}
			},
			handleChange(val){
				if(val){
					let valArr=val.length;
					let id=val[valArr-1]
					this.orgBusinessForm.organizationId=id;
					this.getOrgModule(id)
				}
			},
			refresh(){
				this.load();
			},
			business(){
				this.clickAdd=true;
				this.orgBusinessDialog=true;
				this.orgBusinessForm.reset();
				this.resetForm('orgBusinessForm');
				console.log(this.global);
				this.orgBusinessForm.organizationIds = this.global.orgList;
				this.businession=[];
			},
			disBusiness(index,row){
				this.clickAdd=false;
				this.orgBusinessDialog=true;
				this.orgBusinessForm.reset();
				this.resetForm('orgBusinessForm');
				this.businession=[];
				this.getOrgModule(row.orgId)
				this.id=row.orgId;
			},
			orgBusinessSizeChange(val){
				this.orgBusinessPage.currentPage=1;
				this.orgBusinessPage.pageSize=val;
				this.load();
			},
			orgBusinessCurrentChange(val){
				this.orgBusinessPage.currentPage=val;
				this.load();
			},
			async submit(){
				let param={
					orgId:null,
					moduleIds:[]
				}
				let Array=[];
				if(this.orgBusinessForm.checkList.length) {
					for (let i of this.orgBusinessForm.checkList) {
						let arr = this.businession.find(item => item.name == i)
						Array.push(arr.id)
					}
				}else {
					Array=[];
				}
				if(Array.length){
					param.moduleIds=Array;
				}
				if(this.orgBusinessForm.organizationId){
					param.orgId=this.orgBusinessForm.organizationId;
				}
				if(this.id){
					param.orgId=this.id;
				}
				this.orgBusinessDialog=false;
				let res = await this.$http.post('/sys/distributeOrgModule',param);
				if(res.state==true){
					this.$message({message: '分配成功', type: 'success'});
				}
				this.load();
			},
			async getOrgModule(val){
				let res = await this.$http.post('/sys/orgAllModule',{orgId:val});
				if(res.data){
					this.businession=res.data;
					let Array=[];
					for(let i of this.businession){
						if(i.isTrue=="true"){
							Array.push(i.name)
						}
					}
					this.orgBusinessForm.checkList=Array;
				}else {
					return false;
				}
			},
			async load(){
				let param={
					limit:this.orgBusinessPage.pageSize,
					offSet:(this.orgBusinessPage.currentPage-1)*this.orgBusinessPage.pageSize,
					orgName: this.searchItem.orgName.length? this.searchItem.orgName[this.searchItem.orgName.length-1]: null
				};
				let res = await this.$http.post('/sys/orgModuleList',param);
				if(res.data){
					this.orgBusinessData=res.data.list;
					this.orgBusinessPage.total=res.data.totalCount;
					this.isShowSearchDlg = false;
					this.searchItem.orgName = [];
				}else {
					return false;
				}
			},
		},
		mounted() {
			this.load();
			window.onresize = () => {
				window.fullHeight = document.documentElement.clientHeight
				this.tableHeight = window.fullHeight - 283;
			}
		}
	}
</script>

<style>
</style>